import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo4.png";
import { PatentContext } from "../context/PatentContextProvider";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

//REFS//

const Navbar = ({
  choosenPatent,
  activePatentId,
  showScrollButton,
  // handleLinkClick,
  activeLink,
}) => {
  const [scroll, setScroll] = useState(false);
  const {
    patentSelected,
    patentsData,
    setAnimate,
    setPlayMusic,
    active,
    setActive,
    handlePatentClick,
    menuOpen,
    setMenuOpen,
    selectedPatent,
    handleLinkClick,
  } = useContext(PatentContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isAdminPage =
    location.pathname.includes("/allpages") ||
    location.pathname.includes("/edit") ||
    location.pathname.includes("/pages-create");
  const dropdownRef = useRef(null);
  const dropdownMenu = useRef(null);

  useEffect(() => {
    handlePatentClick(activePatentId);
  }, [activePatentId]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 130);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        dropdownMenu.current &&
        !dropdownRef.current.contains(event.target) &&
        !dropdownMenu.current.contains(event.target)
      ) {
        setIsDropDownOpen(false); // Close the dropdown
      }
    };

    // Attach the event listener
    document.addEventListener("mouseover", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mouseover", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setMenuOpen(false);
  }, []);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  function navigateToHomePage() {
    setPlayMusic(false);
    setAnimate(false);
    navigate("/");
    window.scrollTo(0, 0);
  }
  function navigateToPatent(id) {
    setActive(id);
    localStorage.setItem("activePatent", JSON.stringify(id));
    navigate(`/patent-details/${id}`);
    window.scrollTo(0, 0);
  }

  return (
    <div
      className={`w-full transition-transform duration-300 ease-in-out z-20 ${
        scroll ? "bg-[#393e46] z-20 fixed top-0 " : "bg-black fixed "
      } md:px-5 lg:px-20 flex justify-between lg:justify-between items-center`}
    >
      <div
        className={`' ${
          scroll ? "h-20" : "h-32"
        } relative  flex  items-center py-2'`}
        style={{ cursor: "pointer", width: "25%" }}
      >
        <img
          src={logo}
          alt="logo"
          className={` ${
            scroll ? "w-[4.3rem] sm:pl-0 pl-2" : "w-[7.3rem] pl-2"
          } `}
          onClick={navigateToHomePage}
        />
      </div>

      {isAdminPage ? (
        <button className=" bg-gray-800 text-white px-6 py-3 font-bold rounded-md shadow-md hover:bg-gray-500 transition duration-300">
          Logout
        </button>
      ) : (
        <div className="hidden lg:flex flex-wrap justify-center gap-4 2xl:gap-8 lg:text-[18px] md:text-[16px]">
          {patentsData.map((item) => (
            <div
              className="flex flex-col  items-center justify-center md:gap-2  sm:text-[0.8rem] lg:text-[1rem] font-semibold "
              key={item.Id}
            >
              <p
                className={`cursor-pointer transition delay-300 duration-300 ease-in-out ${
                  active === item.patentId.replace(/[\s,]/g, "")
                    ? `${
                        scroll
                          ? "text-[#00A4E5] border-b-4 border-[#00A4E5]"
                          : "text-white border-b-4 border-[#B0B0B0] py-2 px-4  transition delay-300 duration-300 ease-in-out"
                      }`
                    : `${scroll ? "text-white" : "text-white "}`
                }`}
                onClick={() => {
                  navigateToPatent(item.patentId.replace(/[\s,]/g, ""));
                }}
              >
                {item.patentId}
              </p>
            </div>
          ))}
          <div className="relative pl-3 font-bold pt-0 ">
            <p
              ref={dropdownRef}
              onMouseOver={() => setIsDropDownOpen(!isDropDownOpen)}
              className={`inline-flex cursor-pointer text-white lg:text-[18px] md:text-[16px] justify-center items-center *:
          ${scroll ? "" : "pt-2"}
          `}
            >
              Resources
              <svg
                className={`ml-2 h-5 w-5 transition-transform duration-200 ${
                  isDropDownOpen ? "rotate-180" : "rotate-0"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </p>
            {isDropDownOpen && (
              <div
                ref={dropdownMenu}
                className="absolute right-0 z-50 w-48  origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  <Link
                    to="/"
                    onClick={() => handleLinkClick("/")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    Home
                  </Link>
                    <Link
                                    // to="/working-model"
                                    // onClick={() => handleLinkClick("/working-model")}
                                    className={` relative group block px-4 py-1.5 text-sm ${
                                      activeLink === "/our-blogs"
                                        ? "text-gray-700 bg-"
                                        : "text-gray-700 hover:bg-gray-600 hover:text-white"
                                    }`}
                                  >
                                    Getting Started
                                    <span
                                      className={`ml-2 text-[11px] transform transition-transform duration-300 ease-in-out`}
                                    >
                                      ▼
                                    </span>
                                    <div className="absolute -left-40 -top-1  hidden w-40 bg-white  group-hover:block  border border-gray-200 divide-y z-50 divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <Link
                                        to="/working-model"
                                        onClick={() => handleLinkClick("/working-model")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        How It Works
                                      </Link>
                  
                                      <Link
                                        to="/best-practices"
                                        onClick={() => handleLinkClick("/best-practices")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        Best Practices
                                      </Link>
                                      <Link
                                        to="/use-cases"
                                        onClick={() => handleLinkClick("/use-cases")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        Use Cases
                                      </Link>
                                      <Link
                                        to="/compliance"
                                        onClick={() => handleLinkClick("/compliance")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        Compliance
                                      </Link>
                                      <Link
                                        to="/glossary"
                                        onClick={() => handleLinkClick("/glossary")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        Glossary
                                      </Link>
                                    </div>
                                  </Link>
                  
                  {/* <Link
                    to="/history"
                    onClick={() => handleLinkClick("/history")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    History
                  </Link> */}
                  <Link
                    to="/about-us"
                    onClick={() => handleLinkClick("/about-us")}
                    className={` relative group block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    About 
                    <span
                    className={`ml-2 text-[11px] transform transition-transform duration-300 ease-in-out`}
                  >
                    ▼
                  </span>
                   <div className="absolute -left-40 -top-1  hidden w-40 bg-white  group-hover:block  border border-gray-200 divide-y  divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                   <Link
                                        to="/history"
                                        onClick={() => handleLinkClick("/history")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        History
                                      </Link>
                                      <Link
                                        to="/about-us"
                                        onClick={() => handleLinkClick("/about-us")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        About Us
                                      </Link>
                                      <Link
                                        to="/alert-system"
                                        onClick={() => handleLinkClick("/alert-system")}
                                        className="block px-4 py-1 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
                                      >
                                        Alert System
                                      </Link>
                                     
                                    </div>
                  </Link>

                  {/* <Link
                    to="/alert-system"
                    onClick={() => handleLinkClick("/alert-system")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    Alert System
                  </Link> */}
                  <Link
                    to="/contact-us"
                    onClick={() => handleLinkClick("/contact-us")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/press-releases"
                    onClick={() => handleLinkClick("/press-releases")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    Press Releases
                  </Link>
                  <Link
                    to="/our-blogs"
                    onClick={() => handleLinkClick("/our-blogs")}
                    className={`block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white`}
                  >
                    Blogs
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!isAdminPage && (
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="text-3xl mr-2">
            {menuOpen ? (
              <AiOutlineClose className="text-white" />
            ) : (
              <AiOutlineMenu className="text-white" />
            )}
          </button>
        </div>
      )}

      {menuOpen && (
        <div
          className={` absolute  right-0 bg-blue-200 w-full   py-16  md:py-[4.5rem] flex gap-5 flex-col items-center justify-center z-50 lg:text-[20px] md:text-[16px] ${
            showScrollButton ? "top-20" : "top-32"
          }`}
        >
          {patentsData.map((item) => (
            <div
              className="flex flex-col items-center justify-center gap-2 text-[15px] md:text-[16px] lg:text-[18px] font-semibold"
              key={item.Id}
            >
              <p
                className={`cursor-pointer bg-green ${
                  active === item.patentId ? "text-[#00A4E5] " : "text-black"
                }`}
                onClick={() => {
                  setActive(item.patentId.replace(/[\s,]/g, ""));
                  navigateToPatent(item.patentId.replace(/[\s,]/g, ""));
                }}
              >
                {item.patentId}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
