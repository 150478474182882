import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { IoMdAdd } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";

import "./PageList.css";
import MainNavbar from "../../../../components/MainNavbar";
import CreatePage from "./CreatePage";
import Sidenav from "./SIdeNav";
import BackToAdmin from "./BackToAdmin";
import Navbar from "../../../../components/Navbar";
const PageList = () => {
  const navigate=useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_PATH; 
  const [pages, setPages] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState(null);
   const [isLoading, setIsLoading] = useState(true); 
   const [isSidenavOpen, setIsSidenavOpen] = useState(false); 
  const location = useLocation();



  useEffect(() => {
    if (location.state?.toastMessage) {
      toast.success(location.state.toastMessage);
    }
  // navigate(location.pathname, { replace: true });
  }, [location]);
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/pages/getdata`);
        setPages(response.data);
        setIsLoading(false); // Stop loading once data is fetched
      } catch (err) {
        setError("Failed to fetch pages");
      }
    };
    fetchPages();
  }, [apiBaseUrl]);

  const deletePage = async (id) => {
    try {
      await axios.delete(`${apiBaseUrl}/admin/pages/${id}`);
      setPages((prevPages) => prevPages.filter((page) => page._id !== id));
    } catch (err) {
      setError("Failed to delete page");
    }
  };

  
 return <div className="flex flex-col lg:flex-row bg-white min-h-screen w-full flex-nowrap">
     <div className="lg:hidden bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">All Blogs</h1>
        <button
          onClick={() => setIsSidenavOpen(!isSidenavOpen)}
          className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
        >
          
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
  {/* Sidebar */}
  <div
    className={`${
      isSidenavOpen
        ? "fixed inset-0 transform translate-x-0 bg-gray-800"
        : "fixed inset-0 transform -translate-x-full"
    } lg:relative lg:translate-x-0 lg:w-52 text-white p-4 transition-transform z-50`}
  >
    <Sidenav />
    <button
      onClick={() => setIsSidenavOpen(false)}
      className="lg:hidden absolute top-4 right-4 text-gray-300 hover:text-white"
    >
      Close
    </button>
  </div>

  {/* Main Content */}
  <div className="flex-1 ">
    <div
      className={`flex items-center justify-center py-10 pb-7 pt-16 transition-opacity duration-300 ${
        isSidenavOpen ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen w-full bg-white">
          <div className="w-20 h-20 border-4 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="pt-7 pl-2 w-full"> <div className="bg-white shadow-lg  shadow-gray-700 rounded-lg p-5 lg: space-y-6 w-[90%] mx-auto ">
       
       
         <div className="hidden lg:flex justify-between items-center bg-gray-800 p-4 -mt-12 rounded-lg">
         <h2 className=" text-white  font-semibold  ">All Blogs</h2>
                   
           <Link to="/pages-create" className="flex justify-center items-center bg-slate-200 p-2  px-5 rounded-lg gap-2" >
              <IoMdAdd className="pt-0.5"/>
              <button>Create Blog</button> 
               </Link>    

          </div>                         
   
        
         <div className="overflow-x-auto">
           <table className="w-full bg-white border-collapse rounded-lg shadow-lg">
             <thead>
               <tr className="text-gray-800 text-left bg-gray-100">
                 <th className="px-6 py-4 font-semibold w-1/3">TITLE</th>
                 <th className="px-6 py-4 font-semibold w-1/3">URL</th>
                 <th className="px-4 py-4 font-semibold w-1/6 text-center">STATUS</th>
                 <th className="px-4 py-4 font-semibold w-1/6 text-center">ACTIONS</th>
               </tr>
             </thead>
             <tbody className="divide-y divide-gray-300">
               {pages?.map((page, index) => (
                 <tr key={index} className="hover:bg-gray-50 transition duration-300">
                   <td className="px-6 py-4 text-gray-800 align-middle">{page.title}</td>
                   <td
                     className="px-6 py-4 text-blue-600 hover:underline cursor-pointer align-middle"
                     onClick={() => navigate(`/${page.slug}`)}
                   >
                     {`http://ohanlonip.com/our-blogs/${page.slug}`}
                   </td>
                   <td className="px-4 py-4 text-gray-600 text-center align-middle">
                     {page.status}
                   </td>
                   <td className="px-4 py-4 align-middle">
                     <div className="flex justify-center items-center space-x-3">
                       <button onClick={()=>{
                         navigate(`/pages/edit/${page._id}`)
                         console.log("navigation done")
                       
                       }}
                         
                         className="z-50 text-blue-600 bg-none hover:text-blue-400 transition duration-300 cursor-pointer"
                       >
                         <FaEdit className="w-5 h-5 " />
                       </button>
                       
                       <button
                         onClick={() => {
                           setShowModal(true);
                           setSelectedPageId(page._id);
                         }}
                         className="text-red-600 hover:text-red-400 transition duration-300"
                       >
                         <MdDelete className="w-5 h-5" />
                       </button>
                     </div>
                   </td>
                 </tr>
               ))}
             </tbody>
           </table>
         </div>
       </div></div>
       
      )}
    </div>
  </div>

  {/* Modal */}
  {showModal && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <h2 className="text-xl font-bold text-gray-800">Confirm Delete</h2>
        <p className="mt-2 text-gray-600">Are you sure you want to delete this Page?</p>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            className="px-4 py-2 text-gray-600 bg-gray-100 hover:bg-gray-200 rounded-lg"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-lg"
            onClick={() => {
              deletePage(selectedPageId);
              setShowModal(false);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )}
  <ToastContainer position="top-right" autoClose={3000} />
</div>


{/* Modal */}


 
 



}
export default PageList;
