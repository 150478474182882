import { Footer } from "./Footer";
import MainNavbar from "./MainNavbar";
import USPATENTLAW from "../assets/images/patent.jpg";
import pressPhotos from "../assets/images/Photos of 2004 NAR.jpg";
import historyForm from "../assets/images/Photos of 2004 NAR_002.jpg"

export default function History() {
  return (
    <>
      <MainNavbar />
      <div className=" bg-white pt-40 pb-12">
        <div className="w-[85%] m-auto ">
          <div className="flex flex-col md:flex-row pb-12 gap-12">
            <div className="grow">
              <img
                src={USPATENTLAW}
                className="lg:max-h-[542px] w-full object-fit: cover"
              ></img>
            </div>
            <div className="lg:w-[71%] md:w-3/4">
              <div className=" text-lg">
                <span className="text-3xl block text-[#01a4e4] leading-10 font-bold md:pb-2">
                  The Director of the United States Patent and Trademark Office
                </span>
                <p className="text-[1.42rem] leading-8 pb-3 lg:pb-12 text-[#3b4455]">
                  has received an application for a patentfor a new and useful
                  invention. The title and the description of the invention are
                  enclosed. The requirements of law have been complied with, and
                  it has been determined that a patent on the invention shall be
                  granted under the law. Therefore, this United States Patent
                  Grants to the person(s) having title to this patent the right
                  to exclude othersfrom making, using, offering for sale, or
                  selling the invention throughout the United States of America
                  or importing the invention into the United States ofAmerica,
                  and if the invention is a process, of the right to exclude
                  others from using, offering for sale or selling throughout the
                  United States of America, or importing into the United States
                  of America, products made by that process, for the term
                  setforth in 35 U.S.C. 154(a)(2) or (c)(l), subject to the
                  payment ofmaintenancefees as provided by 35 U.S.C. 410. See
                  the Maintenance Fee Notice on the inside of the cover.
                </p>
              </div>
              <p className="text-2xl text-[#868e96] font-bold pb-2">
                David J. Kaffoos
              </p>
              <p className="text-[#18B295] text-2xl md:text-3xl">
                Director of the United States Patent and Trademark Office
              </p>
            </div>
          </div>

          <div className="text-[#3b4455] text-xl ">
            <h3 className="text-3xl text-[#01a4e4]  font-bold pb-3">
              {" "}
              History Of Kenneth O’Hanlon
            </h3>
            <p className="pb-7">
              The story of Kenneth O’Hanlon, and O’Hanlon IP, LLC began in 2003.
              The idea for the product was a direct result of frustrations I and
              others experienced when trying to view or preview residential
              properties. At that time, the majority of the time, both buyers
              and sellers encountered in the field missing, or dirt-encrusted
              brochures, calls to realtors offices that were closed, and often
              waiteddays for a Realtor to return calls. The MLS was on 3 x 5
              cards and often out of date. The global trends and market
              challenged the marketplace for another way to deliver real-time
              and accurateinformation
            </p>
            <p className="pb-7">
              Like many inventions, they often spring from light bulb moments
              (Thank you Thomas Edison). In the pre-website days of Real Estate
              and at the time, being in the home improvement business, I was
              enormously frustrated by the difficulty of getting real-time
              property information from any real estate office. On a trip to
              visit my original home country of the UK and to visit my daughter,
              we went out to lunch, and I found her engrossed in texting her
              friends. So, I asked her to teach me to text. As a former Royal
              Marine Commando, I spent the next two wonderful hours being
              brought up to speed on the art of texting
            </p>
            <p className="pb-7">
              On the way home, it hit me (what if) you could text into a
              platform and get the information back by text as it was only 140
              characters which is between 20 words and 35 words with spaces, so
              it could say 3 bedrooms 2-bathroom 1750 Sq ft price $300,000. The
              format was there but not the platform
            </p>
            <p className="pb-7">
              After discussions with an attorney friend, he gave me an NDA to
              protect my idea. I then went around talking to internet companies
              looking for a multi-level retrievable platform, I discovered NO
              one at that time had one so using my military communications
              background I went about, and I built the first platform which
              became the basis for both the technology and the patents...
            </p>
            <p className="pb-7">
              After seeing the need for a Real Estate marketing tool, O’Hanlon
              IP, LLC was the first company to exhibit at the National
              Association of Realtors (NAR) in its product category in 2004.in
              Orlando,FL. It was a great show no one had seen something like
              this, BUT there had to be a BUT and the butt was 140 characters.
            </p>
            <p className="pb-7">
              So, I devised an information-gathering IVR Since the IVR was based
              on phone technology I was able to get around the text limitations.
            </p>
            <p className="pb-7">
              {" "}
              As I explored the market, some realtors asked if photos could be
              sent as well. Remember we were still in the age of analog
              cellphones not digital and there were 15 different-sized screens.
              So, Idevised a device recognition system that would send photos of
              the right size to different cell phone formats. Again, this was
              the first for the industry, using this device recognition system I
              could now send video to a cell phone.
            </p>
            <p className="pb-7">
              As with any new technology, copycat companies emerged. By 2007
              there appeared to be eight, but none had the robust capabilities
              of my platform.
            </p>
            <p className="pb-7">
              The Company conducted extensive market research between 2004 and
              2006, utilizing real estate professionals, consumer focus groups,
              and beta testing. Approximately 30 Realtors were involved. As real
              estate companies had historically used property signage to
              advertise the existence of a listing, it was determined to marry
              the technology with the sign. In that context, 250 signs were
              placed out in the marketplace. During the 1000 beta tests on the
              system, consumers were given the option of initiating the request
              with a text message or dialing a toll-free number and entering the
              info when prompted (via an IVR). 940 used and preferred the
              toll-free number and IVR versus 60 who used the text message
              capability.
            </p>

            <h3 className="text-2xl text-[#01a4e4]  font-bold pb-3">
              The value proposition then, as now, for real estate professionals
              who utilize our system are:
            </h3>
            <ol className=" list-decimal pb-7 list-inside">
              <li>
                Being connected to buyers who have not chosen a Realtor yet
              </li>
              <li>
                Increasing listings as home sellers demand this type of lead
                capture and tracking system and will choose Realtors who are
                members versus those that are not
              </li>
              <li>Increasing sales with faster, more qualified leads</li>
              <li>
                Realizing significant cost savings with the eliminated need for
                printed brochures and keeping brochure boxes full, while having
                a real green impact on the environment
              </li>
            </ol>

            <p className="pb-7">
              The beta test results provided clear confirmation of the
              patent-pending process that was filed in 2005 and published in
              2007. A Text Brochure can also be A car van motorbike Plane or
              Motorboat Clothing Antiques anything for sale, plus any services
              on offer.
            </p>
            <p>
              The first patent was issued on January 10, 2010, and subsequent
              patents were issued in 2015 and 2016
            </p>
            <div className="flex items-center justify-center pt-5 flex-col">
              <img className="pb-5"
                src={pressPhotos}
                alt="Team Members at Press Conference"
              />
               <img
                src={historyForm}
                alt="Reciept"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
