import MainNavbar from "./MainNavbar";

export default function Hero({ bgImage, imageText }) {
  return (
    <div>
      <MainNavbar />

      {bgImage ? (
        <div
          className={` relative pl-0 md:pl-8 py-40 text-left md:p-56 bg-cover bg-center text-white bg-gradient-to-b from-black/50 to-black/80`}
          style={{
            backgroundImage: `
          url(${bgImage})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="flex justify-center items-center flex-col relative z-10   ">
            <h3 className="text-sm scroll-pl-20 md:pl-56 text-center block md:inline-block md:text-5xl pb-8 font-bold  md:text-center">
              {imageText.title}
            </h3>
            <p className=" text-sm md:text-2xl px-5 md:pl-60 text-center">
              {imageText.description}
            </p>
          </div>
        </div>
      ) : (
        <div className="bg-black px-16 py-36 md:p-40 text-white">
          <div className="flex-col justify-center items-center">
            <h3 className=" text-4xl md:text-5xl pb-8 font-bold text-center">
              {imageText?.title}
            </h3>
            {imageText?.description && (
              <p className="text-2xl text-center text-gray-400 pt-4">{`... ${imageText?.description}`}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
