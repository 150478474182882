import OfficeBg from "../assets/images/office.jpg";
import { bgImageContactText } from "../constants/constants";
import MainNavbar from "./MainNavbar";
import Hero from "./Hero";
import { Footer } from "./Footer";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Contact() {
  const baseUrl=process.env.REACT_APP_API_PATH;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({mode :"onBlur"});
  // const watchedData=watch();
  const onSubmit = async(data) => {
    // setContactData(watchedData);
    try{
      const response=await axios.post(`${baseUrl}/contact`,data);
      if(response.status===201){
        toast.success("Your response has been successfully submitted")
      }

    }
   
    catch(err){
         console.log(err,"error");
         toast.error("Something went wrong try again") 
    }
    reset();
  };
  return (
    <>
      <MainNavbar />
      <Hero bgImage={OfficeBg} imageText={bgImageContactText} />
      
      <div className="bg-white pt-5">
     
        <form className="w-11/12 m-auto py-7" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <label
              for="fullName"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              {...register("fullName", {
                required: " Fullname is required",
                validate: {
                  maxLength: (v) =>
                    (v.length <= 30 && v.length > 2) ||
                    "The name should have at least 2 characters at most 30 characters ",
                  matchPattern: (v) =>
                    /^[a-zA-Z ]{2,30}$/.test(v) ||
                    "Name must be a valid name only alphabets are allowed",
                },
              })}
            />
            {errors.fullName && (
              <small
                style={{
                  color: "red",
                  fontSize: "12px",
                  display: "block",
                  paddingTop: "5px",
                }}
              >
                {errors.fullName.message}
              </small>
            )}
          </div>
          <div className="mb-6">
            <label
              for="email"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email
            </label>

            <input
              type="text"
              id="email"
              className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              {...register("email", {
                required: "Email is required",
                validate: {
                  maxLength: (v) =>
                    v.length <= 50 ||
                    "The email should have at most 50 characters",
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "email address must be a valid address",
                },
              })}
            />
            <small>We'll never share your email with anyone else.</small>
            {errors.email && (
              <small
                style={{ color: "red", fontSize: "12px", display: "block" }}
              >
                {errors.email.message}
              </small>
            )}
          </div>

          <div className="mb-6">
            <label
              for="subject"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              {...register("subject", {
                required: "Subject is required",
              })}
            />
            {errors.subject && (
              <small
                style={{ color: "red", fontSize: "12px", display: "block" }}
              >
                {errors.subject.message}
              </small>
            )}
          </div>
          <div className="mb-6">
            <label
              for="message"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Your message
            </label>

            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-600 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write your thoughts here..."
              {...register("message", {
                required: "Message is required",
              })}
            ></textarea>
            {errors.message && (
              <small
                style={{ color: "red", fontSize: "12px", display: "block" }}
              >
                {errors.message.message}
              </small>
            )}
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="text-white 
             bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-md px-16 py-5 me-2 mb-2 "
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
        <Footer />
        <ToastContainer position="top-right" autoClose="2000"/>
      </div>
    </>
  );
}
