import Hero from "../Hero";
import MainContent from "../MainContent";
import MainNavbar from "../MainNavbar";
import { operationModel } from "../../constants/constants";
import CommonMainSection from "../CommonMainSection";
import { Footer } from "../Footer";
export default function  WorkingModel(){

    return <>
    <MainNavbar/>
    <Hero bgImage="" imageText={{ title: "How and Why Mobile Marketing Works", description:"What makes Mobile Marketing Different?" }}/>
    <CommonMainSection content={operationModel}/>
    <Footer/>
    </>
}