import { bestPractices } from "../../constants/constants";
import CommonMainSection from "../CommonMainSection";
import { Footer } from "../Footer";
import Hero from "../Hero";
import MainNavbar from "../MainNavbar";

export default function BestPractices(){
    return<>
    <MainNavbar/>
     <Hero bgImage="" imageText={{ title: "Recommended Best Practices",description:"Some Guidelines on Creating the Best Mobile Marketing Campaigns" }}/>
     <CommonMainSection content={bestPractices}/>
     <Footer/>
    </>
}