import { useLocation,useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from "axios";
import Hero from "../../../../components/Hero";
import MainContent from "../../../../components/MainContent";
import BlogMainContent from "./BlogMainContent";
import { Footer } from "../../../../components/Footer";
import { useNavigate } from "react-router-dom";

export default function DetailPage(){

    const location = useLocation();
    const page=location.state;
    const apiBaseUrl = process.env.REACT_APP_API_PATH;
    const { slug } = useParams();
    const navigate = useNavigate();
    const [pages, setPages] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const fetchPage = async () => {
        try {
          const response = await axios.get(`${apiBaseUrl}/pages/${slug}`);
          setPages(response.data);
          
          setLoading(false);
        } catch (err) {
          setError("Page not found");
          navigate('/')
          setLoading(false);
        }
      };
      fetchPage();
    }, [slug, apiBaseUrl]);    
 

    return<div className="flex flex-col min-h-screen bg-white">
      
       { <Hero bgImage={""} imageText={{title:pages?.title}} /> }
       <BlogMainContent content={pages?.content} className="flex-grow"/> 
     <Footer/> 
    
   
    
    </div>

}


