import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import WYSIWYGEditor from "./WYSIWYGEditor";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PageList.css";
import Sidenav from "./SIdeNav";
import BackToAdmin from "./BackToAdmin";

const CreatePage = () => {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [metadata, setMetadata] = useState({ description: "", keywords: "" });
  const [status, setStatus] = useState("draft");
  const [isSidenavOpen, setIsSidenavOpen] = useState(false); // For toggling sidenav visibility

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("title", title);
  //   formData.append("slug", slug);
  //   formData.append("content", content);
  //   formData.append("featuredImage", featuredImage);
  //   formData.append("status", status);
  //   formData.append("metadata", JSON.stringify(metadata));

  //   try {
  //  const response  =  await axios.post(`${apiBaseUrl}/admin/pages`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //     console.log('response', response.status)
  //     navigate("/allpages", { state: { toastMessage: "Page Has Been Created!" } });
  //   } catch (err) {
  //     console.log('errror', err);
  //     toast.error("Failed To Create Page");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare form data
    const formData = new FormData();
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("content", content);
    formData.append("featuredImage", featuredImage);
    formData.append("status", status);
    formData.append("metadata", JSON.stringify(metadata));
  
    // Helper function for API call
    const createPage = async (data) => {
      try {
        const response = await axios.post(`${apiBaseUrl}/admin/pages`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
      } catch (error) {
        throw error.response || error; // Throw response for detailed error handling
      }
    };
  
    // Submit form and handle response
    try {
      const response = await createPage(formData);
  
      // Check for successful response
      if (response?.status === 201) {
        navigate("/allpages", { state: { toastMessage: "Page Has Been Created!" } });
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      // Check for specific status codes and handle messages accordingly
      if (error?.status === 400) {
        toast.error("Page title already exists");
      } else {
        const errorMessage =
          error?.data?.message || "An unexpected error occurred while creating the page.";
        // toast.error(errorMessage);
      }
      console.error("Error creating page:", error);
    }
  };
  
  

  return (
    <div className="flex flex-col lg:flex-row bg-white min-h-screen w-full flex-nowrap ">
      {/* Mobile Menu Icon */}
      <div className="lg:hidden bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Create Blog</h1>
        <button
          onClick={() => setIsSidenavOpen(!isSidenavOpen)}
          className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
        >
          {/* Menu Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Sidebar */}
      <div
  className={`${
    isSidenavOpen
      ? "fixed inset-0 transform translate-x-0 bg-gray-800"
      : "fixed inset-0 transform -translate-x-full"
  } lg:relative lg:translate-x-0 lg:w-52  text-white p-4 transition-transform z-50`}
>
  
  <Sidenav />
  <button
    onClick={() => setIsSidenavOpen(false)}
    className="lg:hidden absolute top-4 right-4 text-gray-300 hover:text-white"
  >
    Close
  </button>
</div>

      {/* Main Content */}
      
      <div className="  flex-1 pl-2.5">
      <div className={`flex items-center justify-center pt-20 py-10 pb-3  ${
          isSidenavOpen ? "opacity-50 pointer-events-none" : ""
        } transition-opacity duration-300`}
        
        >
       <div className="pt-7">
        
        
        </div>   
      <form onSubmit={handleSubmit} className="bg-white shadow-lg  shadow-gray-700 rounded-lg p-5 pt-16 pl-8 lg: space-y-6 w-[90%] mx-auto ">
      
      
       <div className=" hidden lg:flex justify-between items-center bg-gray-800 p-3 -mt-20 rounded-lg ">
                         <h2 className=" text-white  font-semibold  ">Create Blog</h2>
                         <BackToAdmin/>
                           </div>  
       <div>
       <input
         type="text"
         placeholder="Page Title"
         value={title}
         onChange={(e) => {setTitle(e.target.value)
           const formedSlug=  e.target.value.trim().replace(/^\s+/, '') // Remove leading and trailing whitespace
           .replace(/\s+/g, '-') .split('-') 
           .slice(0, 4).join('-').toLowerCase();
 
           setSlug(`${formedSlug}`.replace(/[^a-zA-Z0-9-]/g, ''));
 
 
         }}
         
         required
         className="w-full px-4 py-3 mb-5 mt-5 border border-gray-500 rounded-md focus:ring-2 focus:ring-gray-600 focus:outline-none focus-visible:outline-gray-500"
       />
       </div>
       {/* <div className="h-72 border border-gray-500 rounded-md"> */}
       <WYSIWYGEditor 
      
       value={content}   onChange={setContent} className="border ql-editor  border-gray-500 rounded-md py-5 custom-editor" />
 
       
       
       <input
         type="file"
         onChange={(e) => setFeaturedImage(e.target.files[0])}
         className="pt-2 pb-2"
       />
      
     
      
      <div className="flex gap-3  mt-2">
      <button type="submit" className="w-full bg-gray-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-gray-600" onClick={()=>setStatus("draft")}>Save as Draft</button>
      <button type="submit" className="w-full bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-600" onClick={()=>setStatus("published")}>Publish</button>
 
      </div>
      
      
      
      
     
       
     </form>
     </div>
   </div>
    <ToastContainer />

    </div>
  );
};

export default CreatePage;
