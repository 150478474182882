import { useContext } from "react";
import { PatentContext } from "../context/PatentContextProvider";
import { Link } from "react-router-dom";
export function Footer() {
  const { activeLink, handleLinkClick } = useContext(PatentContext);
  return (
    <div className="bg-[#222831] pt-5 text-white  ">
      <div className="w-3/4 m-auto flex gap-12">
        <div className="md:w-1/3">
          <h4 className="text-3xl text-[#c1c0b9] pb-4"> Links</h4>
          <ul>
            {/* <li className="text-xl pb-4 font-bold ">
              <Link onClick={() => handleLinkClick("/history")} to="/history">
                History
              </Link>
            </li> */}
            <li className="text-xl pb-4 font-bold ">
              <Link onClick={() => handleLinkClick("/about-us")} to="/about-us">
                About 
              </Link>
            </li>
            {/* <li className="text-xl pb-4 font-bold ">
              <Link
                onClick={() => handleLinkClick("/alert-system")}
                to="/alert-system"
              >
                Alert System
              </Link>
            </li> */}
             <li className="text-xl pb-4 font-bold ">
              <Link
                onClick={() => handleLinkClick("/working-model")}
                to="/working-model"
              >
                Getting Started
              </Link>
            </li>
            <li className="text-xl pb-4 font-bold ">
              <Link
                onClick={() => handleLinkClick("/press-releases")}
                to="/press-releases"
              >
                Press Releases
              </Link>
            </li>

            <li className="text-xl pb-4 font-bold ">
              <Link
                onClick={() => handleLinkClick("/our-blogs")}
                to="/our-blogs"
              >
                Our Blogs
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-3xl text-[#c1c0b9] pb-4">Support</h4>
          <ul>
            <li className="text-xl pb-4 font-bold ">
              <Link
                onClick={() => handleLinkClick("/contact-us")}
                to="/contact-us"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
