import Hero from "../Hero";
import OfficeBg from "../../assets/images/office.jpg";
import { bgImageOfficeText } from "../../constants/constants";
import ContactUs from "../ContactUs";
import { companyData } from "../../constants/constants";
import MainContent from "../MainContent";
import { Footer } from "../Footer";

export default function Company() {
  return (
    <div className="flex flex-col min-h-screen">
      <Hero bgImage={OfficeBg} imageText={bgImageOfficeText} />
      <MainContent content={companyData} className="flex-grow" />
      {/* <ContactUs /> */}
     
      <Footer />
    </div>
  );
}
