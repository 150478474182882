import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PatentDetails from "./components/PatentsDetails";
import PatentContextProvider from "./context/PatentContextProvider";
import Claims from "./components/Claims";
import Leadership from "./components/About/Leadership";
import Company from "./components/About/Company";
import Alert from "./components/About/Alert";
import Contact from "./components/Contact";
import History from "./components/History";
import PressReleases from "./components/PressReleases";
import PageList from "./customcms/src/components/admin/PageList";
import AdminPanel from "./customcms/src/components/admin/AdminPanel";
import PageTemplate from "./customcms/src/components/PageTemplate";
import CreatePage from "./customcms/src/components/admin/CreatePage";
import EditPage from "./customcms/src/components/admin/EditPage";
import useCMSApi from "./customcms/src/hooks/useCMSApi";
import AdminLogin from "./customcms/src/components/admin/Login";
import Blogs from "./customcms/src/components/admin/Blogs";
import DetailPage from "./customcms/src/components/admin/DetailPage";
import ProtectedRoute from "./components/ProtectedRoute"; // Import ProtectedRoute component
import AdminAlert from "./customcms/src/components/admin/AdminAlert";
import RegisteredContacts from "./customcms/src/components/admin/RegisteredContacts";
import WorkingModel from "./components/Operations/WorkingModel";
import BestPractices from "./components/Operations/BestPractices";
import UseCases from "./components/Operations/UseCases";
import Glossary from "./components/Operations/Glossary";
import Compliance from "./components/Operations/Compliance";
import ScrollToTop from "./components/ScrollToTop";

const PatentIntro = React.lazy(() => import("./components/PatentIntro"));

function App() {
  // const { pages, loading, error } = useCMSApi();

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div className="bg-white">{error}</div>;

  return (
    <div className="App overflow-hidden">
      <PatentContextProvider>
        <Router>
          <ScrollToTop/>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense
                  fallback={<div className="h-screen bg-white">Loading...</div>}
                >
                  <PatentIntro />
                </Suspense>
              }
            />

            <Route
              path="/patent-details/:patentId"
              element={<PatentDetails />}
            />
            <Route path="/claims" element={<Claims />} />
            <Route path="/about-us" element={<Company />} />
            <Route path="/leadership" element={<Leadership />} />
            <Route path="/history" element={<History />} />
            <Route path="/alert-system" element={<Alert />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/press-releases" element={<PressReleases />} />
            <Route path="/working-model" element={<WorkingModel />} />
            <Route path="/best-practices" element={<BestPractices />} />
            <Route path="/use-cases" element={<UseCases />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/compliance" element={<Compliance />} />
            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/allpages" element={<PageList />} />
              <Route path="/pages-create" element={<CreatePage />} />
              <Route path="/pages/edit/:id" element={<EditPage />} />
              <Route path="/alerts" element={<AdminAlert/>} />
              <Route path="/contact-requests" element={<RegisteredContacts/>}/>
            </Route>

            <Route path="/login" element={<AdminLogin />} />
           
            <Route path="/:slug" element={<DetailPage />} />
            <Route path="/our-blogs" element={<Blogs />} />
            <Route path="*" element={<PatentIntro />} />
            {/* <Route path="/pages-create" element={<CreatePage />} /> */}
          </Routes>
        </Router>
      </PatentContextProvider>
    </div>
  );
}

export default App;
