import { useForm } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PatentContext } from "../../../../context/PatentContextProvider";

export default function AdminLogin() {
  const { handleLinkClick } = useContext(PatentContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (location.state?.isLoggedOut) {
      // Display the toast
      toast.success("Successfully logged out");

      // Clear the state to prevent the toast from reappearing
      navigate(location.pathname, { replace: true });
    }
  }, [location,navigate]);

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    handleLinkClick("/allpages");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/login`,
        data
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token); // Store JWT token in local storage
        navigate("/allpages", { state: { isLoggedIn: true } });
        toast.success('User logged in successfully');
      }
      reset();
    } catch (error) {
      toast.error("Invalid Login Credential:", error.response?.data || error.message);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-300 to-gray-400">
      <form
        className="max-w-md lg:w-1/3 m-auto py-10 flex flex-col justify-center items-center bg-white text-black rounded-lg shadow-lg p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-2xl font-bold mb-8">Admin Login</h2>
        <div className="mb-6 w-full">
          <label htmlFor="email" className="block mb-2 text-sm font-medium">
            Email
          </label>
          <input
            type="text"
            id="email"
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-3 shadow-sm"
            {...register("email", {
              required: "Email is required",
              validate: {
                maxLength: (v) => v.length <= 50 || "The email should have at most 50 characters",
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email address must be a valid address",
              },
            })}
          />
          {errors.email && (
            <small className="text-red-400 mt-1 block text-sm">
              {errors.email.message}
            </small>
          )}
        </div>
        <div className="mb-6 w-full relative">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-black">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-3 shadow-sm"
            {...register("password", {
              required: "Password is required",
              validate: {
                matchPattern: (v) =>
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) ||
                  "Password must have at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character.",
              },
            })}
          />
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            className="absolute right-3 top-10 text-gray-600 hover:text-gray-800"
          >
            {showPassword ? (
             <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" id="mdi-eye" viewBox="0 0 24 24"><path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"></path></svg>
            ) : (
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z"></path>
              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z"></path>
            </svg>
            )}
          </button>
          {errors.password && (
            <small className="text-red-400 mt-1 block text-sm">
              {errors.password.message}
            </small>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-500 focus:outline-none"
        >
          Login
        </button>
      </form>
      <ToastContainer position="top-right" autoClose={2000} />
    </div>
  );
}
