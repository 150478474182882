import axios from "axios"
import { useEffect, useState } from "react"
import Sidenav from "./SIdeNav";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import BackToAdmin from "./BackToAdmin";
export default function AdminAlert(){
    const [allerts,setAllerts]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
     const [isSidenavOpen, setIsSidenavOpen] = useState(false); 
    const baseUrl=process.env.REACT_APP_API_PATH
    useEffect(()=>{
async function fetchAlerts(){
    try{
        const response= await axios.get(`${baseUrl}/alertSystem`);
        console.log(response.data)
        setAllerts(response?.data)
        setIsLoading(false)
    }
    catch(err){
      console.log(err,"error")
      setIsLoading(false)
    }
   
}
fetchAlerts();


    },[baseUrl])
    return <div className="flex flex-col lg:flex-row bg-white min-h-screen w-full flex-nowrap" >
          <div className="lg:hidden bg-gray-800 text-white p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50">
      <h1 className="text-xl font-bold">All Alerts</h1>
      <button
        onClick={() => setIsSidenavOpen(true)}
        className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      </div> 
      <div
      className={`${
        isSidenavOpen
          ? "fixed inset-0 transform translate-x-0 bg-gray-800"
          : "fixed inset-0 transform -translate-x-full"
      } lg:relative lg:translate-x-0 lg:w-52 text-white p-4 transition-transform z-50`}
    >
      <Sidenav />
      
      <button
        onClick={() => setIsSidenavOpen(false)}
        className="lg:hidden absolute top-4 right-4 text-gray-300 hover:text-white"
      >
        Close
      </button>
    </div>
    {isSidenavOpen && (
      <div
        onClick={() => setIsSidenavOpen(false)}
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
      ></div>
    )}
   
    <main className="flex-1 pl-2.5">
   <div className={`flex items-center justify-center py-10 pt-20 pb-7 transition-opacity duration-300 ${
        isSidenavOpen ? "opacity-50 pointer-events-none" : ""
      }`}
    >
  {isLoading ?<Loader/>: <div className="bg-white shadow-lg  shadow-gray-700 rounded-lg pt-5 lg: space-y-6 w-[90%] mx-auto px-5  ">
   <div className=" hidden lg:flex justify-between items-center bg-gray-800 p-4 -mt-12 rounded-lg ">
                 <h2 className=" text-white  font-semibold  ">All Alerts</h2>
                 <BackToAdmin/>
                   </div>  
    <ul className="bg-white rounded-lg shadow-md py-7 pl-6 divide-y divide-gray-200">
  {/* Header Row */}
  <li className="flex justify-between items-center font-semibold text-gray-700 uppercase text-sm pb-3">
    <span className="flex-1 text-left">Firstname</span>
    <span className="flex-1 text-left hidden md:inline-block">Lastname</span>
    <span className="flex-1 text-left">Email</span>
  </li>  

  {/* Data Rows */}
  {allerts.map((alert, index) => (
    <li 
      key={index} 
      className="flex justify-between items-center py-4 hover:bg-gray-100 transition duration-200 ease-in-out text-gray-600 text-sm"
    >
      <span className="flex-1 text-left">{alert.firstName}</span>
      <span className="hidden md:flex justify-left items-center md:flex-1 md:text-left ">{alert.lastName}</span>
      <span className="flex-1 text-left">{alert.email}</span>
    </li>
  ))}
</ul>


    </div>}
  
   </div>
    </main>
    
    </div>
}