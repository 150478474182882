import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WYSIWYGEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
    className="custom-quill-editor"   
      theme="snow"
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline","strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link", "image", "video"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  [{ script: "sub" }, { script: "super" }],
  ["blockquote", "code-block"],
  [{ font: [] }, ],
],
      }}
     
    />
  );
};

export default WYSIWYGEditor;
