export default function MainContent({ content }) {
  return (
    <div className=" bg-white pt-10 pb-5 flex-grow ">
      <div
        className={`${
          content.patents
            ? "w-[85%] m-auto text-left"
            : "w-[85%] m-auto text-center"
        }`}
      >
        <h2 className="text-[#01a4e4] text-4xl  font-bold pb-8">
          {content.title}
        </h2>
        <p className="pb-8 text-[#3b4455] text-2xl pl-2">{content.services}</p>
        {content.features?.map((feature) => (
          <div className=" text-lg">
            {feature.featureName && (
              <p className="pb-8 text-2xl text-[#3b4455]">
                {feature.featureName}
              </p>
            )}
            <p className="pb-8 text-2xl text-[#3b4455]">
              {feature.featureInfo}
            </p>
          </div>
        ))}
        {content.patents && (
          <ol className="list-decimal list-inside">
            {content.patents.map((patent) => (
              <li className="pb-3 text-xl text-[#3b4455] ">{patent}</li>
            ))}
          </ol>
        )}
      </div>
    </div>
  );
}
