import MainNavbar from "./MainNavbar";
import React, { useState, useContext, useEffect } from "react";
import { PatentContext } from "../context/PatentContextProvider.js";
import { FaArrowUp } from "react-icons/fa6";
import { Footer } from "./Footer.js";

export default function PressReleases() {
  const { pressReleases } = useContext(PatentContext);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 130) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowScrollButton(false);
  };

  return (
    <>
      <MainNavbar />
      <div className="flex justify-center items-center">
        <div className="w-11/12 lg:w-4/5  bg-white mt-36 mb-3 py-5 px-6 rounded-lg shadow-lg">
          <h2 className="text-3xl text-[#01a4e4] pb-5 font-bold text-center">
            Press Releases
          </h2>
          {pressReleases.map((article, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row gap-5 pb-5 border-t-2 border-[#01a4e4] pt-5"
            >
              <div className="text-sm md:text-base font-medium w-full md:w-[15%] min-w-[120px] text-center md:text-left">
                {article.dateOfRelease}
              </div>
              <div className="flex-1">
                <h3 className="text-gray-800 font-bold text-lg mb-2">
                  {article.title}
                </h3>
                <p className="text-gray-600 text-sm md:text-base mb-3">
                  {article.info}
                </p>
                <a
                  href={article.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-blue-500 hover:text-blue-700"
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        {showScrollButton && (
          <button
            className="fixed bottom-5 right-7 md:bottom-10 md:right-10 lg:bottom-16 lg:right-16 bg-black bg-opacity-60 text-white rounded-full p-3 text-xl md:text-2xl shadow-lg hover:bg-opacity-80"
            onClick={scrollToTop}
          >
            <FaArrowUp />
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
