import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { PatentContext } from "../../../context/PatentContextProvider";
// import { PatentContext } from "../context/PatentContextProvider";

const useCMSApi = () => {
  const apiBaseUrl = process.env.REACT_APP_API_PATH;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  // const { setAllPages } = useContext(PatentContext);
  const [pages,setPages]=useState([])
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/pages/getdata`);
        setPages(response.data);
        // setAllPages(response.data)
        setLoading(false);
      } catch (err) {
        setError("Failed to load pages");
        setLoading(false);
      }
    };
    fetchPages();
  }, [apiBaseUrl]);

  return { pages, loading, error };
};

export default useCMSApi;
