import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
const ProtectedRoute = () => {
    const token = localStorage.getItem('token');  // Get the JWT token from localStorage
   
  
    if (!token) {
      return <Navigate to="/" />;
    }
  
    return <Outlet />;
  };
  
  export default ProtectedRoute;
