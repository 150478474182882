import axios from "axios"
import { useEffect, useState } from "react"
import Sidenav from "./SIdeNav";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackToAdmin from "./BackToAdmin";
import Loader from "./Loader";
export default function RegisteredContacts(){
    const [users,setUsers]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
     const [isSidenavOpen, setIsSidenavOpen] = useState(false); 
    const baseUrl=process.env.REACT_APP_API_PATH
    useEffect(()=>{
async function fetchContacts(){
    try{
        const response= await axios.get(`${baseUrl}/contact`);
        console.log(response.data);
        setUsers(response?.data)
        setIsLoading(false)
    }
    catch(err){
      console.log(err,"error")
      setIsLoading(false)
    }
   
}
fetchContacts();


    },[baseUrl])
    return <div className="flex flex-col lg:flex-row bg-white min-h-screen w-full flex-nowrap">
    
    <div className="lg:hidden bg-gray-800 text-white p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50">
      <h1 className="text-xl font-bold">Contact Requests</h1>
      <button
        onClick={() => setIsSidenavOpen(true)}
        className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  
    
    <div
  className={`${
    isSidenavOpen
      ? "fixed inset-0 transform translate-x-0 bg-gray-800 h-[96%]"
      : "fixed inset-0 transform -translate-x-full"
  } lg:relative lg:translate-x-0 lg:w-52  text-white p-4 transition-transform z-50 `}
>
  
  <Sidenav/>
  <button
    onClick={() => setIsSidenavOpen(false)}
    className="lg:hidden absolute top-4 right-4 text-gray-300 hover:text-white"
  >
    Close
  </button>
</div>
    
    {/* {isSidenavOpen && (
      <div
        onClick={() => setIsSidenavOpen(false)}
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
      ></div>
    )} */}
  
    {/* Main Content */}
    <main className="flex-1 pl-2.5">

    <div className={`flex items-center justify-center py-10 pt-20 pb-7 transition-opacity duration-300 ${
        isSidenavOpen ? "opacity-50 pointer-events-none" : ""
      }`}
    >      

      {isLoading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow-lg shadow-gray-700 rounded-lg pt-5 lg:space-y-6 w-[90%] mx-auto ">
          {/* Desktop Header */}
          <div className="hidden lg:flex justify-between items-center bg-gray-800 p-4 mx-8 -mt-12 rounded-lg">
            <h2 className="text-white font-semibold">Contact Requests</h2>
            <BackToAdmin />
          </div>
  
          {/* Contact Requests List */}
          <ul className="bg-white rounded-lg shadow-md py-7 px-6 divide-y divide-gray-200">
            {users.map((contact, index) => (
              <li
                key={index}
                className="shadow-lg shadow-gray-700 rounded-lg py-5 lg:w-[98%] ml-2  px-5 flex flex-col lg:flex-row gap-16 mb-8"
              >
                <div className="flex items-center justify-center">
                  <div className="h-12 w-12 rounded-full bg-gray-800 text-white flex items-center justify-center text-xl font-bold">
                    {contact.fullName[0].toUpperCase()}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold text-gray-800">
                      {contact?.fullName}
                    </h3>
                    <p className="text-sm text-gray-500">{contact?.email}</p>
                  </div>
                </div>
                <div>
                  <p className="text-xl text-[#36454F] font-semibold">
                    {contact?.subject}
                  </p>
                  <p className="text-[#3b4455]">{contact?.yourMessage}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
     </div> 
    </main>
  </div>
  
}