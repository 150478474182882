import { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import data from "../data/patents.json";
import { PatentContext } from "../context/PatentContextProvider";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { useLocation } from "react-router-dom";
import ViolationModal from "./Modal/ViolationModal";
import Bot from "./ChattingBot";
import Navbar from "./Navbar";
import PatentDescription from "./PatentDescription";
import { HfInference } from "@huggingface/inference";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
export default function Claims() {
  const navigate = useNavigate();
  const { selectedPatent, active, setActive } = useContext(PatentContext);

  const location = useLocation();

  const { activePatent } = location.state ? location.state : selectedPatent;

  useEffect(() => {
    setActive(active);
    localStorage.setItem("activePatent", JSON.stringify(active));
  }, []);

  const hf = new HfInference("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");

  const [claimIndex, setClaimIndex] = useState(0);
  const [voiceType, setVoiceType] = useState(null);
  const [enableChat, setEnableChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const audioRef = useRef(null);

  //functions//

  function handleNext() {
    if (
      (activePatent?.claims && claimIndex < activePatent.claims.length - 1) ||
      (selectedPatent?.claims && claimIndex < selectedPatent?.claims.length - 1)
    ) {
      setClaimIndex((prev) => prev + 1);
    }
  }

  function handlePrevious() {
    if (claimIndex > 0) {
      setClaimIndex((prev) => prev - 1);
    }
  }
  //chatbot
  const openChat = () => {
    setEnableChat(true);
  };
  const closeChat = () => {
    setEnableChat(false);
  };

  const generateSummary = async (languageStyle) => {
    if (!activePatent?.claims) return;
    setLoading(true);
    setVoiceType(languageStyle);

    try {
      const getResponse = await axios.post(
        `${process.env.REACT_APP_API_PATH}/generateSummary`,
        {
          languageStyle: languageStyle,
          activePatent: activePatent.claims[claimIndex],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const generatedSummary = getResponse.data.response.candidates[0]?.content?.parts[0].text
        .replace(/[^\w\s]/g, "")
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s{2,}/g, "");
      setLoading(false);
      if (generatedSummary) {
        speakSummary(generatedSummary);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function navigatebackToPatent(id) {
    navigate(`/patent-details/${id}`);
  }

  async function speakSummary(text) {
    //4th option best till now //

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/generateSpeech`,
        { text: text },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], { type: "audio/mpeg" });
      const url = URL.createObjectURL(blob);

      // Optional: Play the audio
      const audio = new Audio(url);

      audio.addEventListener("play", () => setAudioPlaying(true));

      // Store the instance in the ref
      audioRef.current = audio;
      // audio.load();
      // Play the audio
      await audio.play();

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current = new Audio(url);
      audioRef.current.play();
      setAudioPlaying(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setAudioPlaying(false);
  };
  const previousDisabled = claimIndex === 0;
  const nextDisabled = activePatent
    ? activePatent?.claims && claimIndex === activePatent.claims.length - 1
    : selectedPatent?.claims &&
      claimIndex === selectedPatent?.claims.length - 1;

  return (
    <>
      {
        <ViolationModal
          claim={activePatent?.claims[claimIndex]}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }

      <Navbar />
      <PatentDescription
        patent={activePatent ? activePatent : selectedPatent}
      />

      <div className="flex flex-col justify-between overflow-y-auto  gap-2  pb-16">
        <div className="bg-white py-5 pb-3 md:py-7 px-5 w-[93%] mx-auto max-w-[1350px] rounded-lg relative">
          {location.state ? (
            <div>
              <div className="flex items-center ">
                <div className="w-[30%] md:w-2/5">
                  <Link
                    onClick={() =>
                      navigatebackToPatent(
                        activePatent
                          ? activePatent.patentId.replace(/[\s,]/g, "")
                          : selectedPatent.patentId.replace(/[\s,]/g, "")
                      )
                    }
                    className="text-[#00A4E5] hover:text-blue-400 underline font-semibold flex text-md md:text-2xl mt-[-15px]"
                  >
                    <span>
                      <IoIosArrowBack className=" mt-4 -ml-3 md:mt-1.5 " />
                    </span>
                    <span> Back to Patent</span>
                  </Link>
                </div>

                <h3 className="text-3xl lg:text-4xl xl:text-5xl font-semibold leading-2 text-[#00A4E5] pt-5 pb-8 font-openfont  text-start">
                  Claim {claimIndex + 1}
                </h3>
              </div>
              <p>
                {activePatent?.claims
                  ? activePatent?.claims[claimIndex]
                  : data.patents[0].claims[claimIndex]}
              </p>
            </div>
          ) : (
            <p>{data.patents[0].claims[claimIndex]}</p>
          )}

          <div className="flex  px-[-7px] pt-1 pb-40 md:px-2 justify-between lg:px-5 md:pt-5 md:pb-5 ">
            <button
              className={`${
                previousDisabled
                  ? "bg-gray-400 text-gray-200 cursor-not-allowed "
                  : "bg-[#00A4E5] "
              } p-3 text-white rounded-md`}
              onClick={handlePrevious}
              disabled={previousDisabled}
            >
              Previous
            </button>
            <button
              className={`${
                nextDisabled
                  ? "bg-gray-400 text-gray-200 cursor-not-allowed "
                  : "bg-[#00A4E5] "
              } p-3 text-white rounded-md`}
              onClick={handleNext}
              disabled={nextDisabled}
            >
              Next
            </button>
          </div>
        </div>

        <div className="flex justify-center m-auto w-3/4 max-w-md gap-2">
          <div className="fixed bottom-1 gap-2 flex flex-col lg:gap-5 md:mt-20  md:pt-8  md:w-[96%]  lg:flex  lg:flex-row  items-center lg:w-[92%]  justify-center">
            {/* Summary buttons */}
            <button
              className="bg-[#393e46]  w-[100%] px-3 py-2.5 text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("Legal");
              }}
            >
              {loading && voiceType === "Legal" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "Legal" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary In Legal Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-3 py-2.5  w-[100%] text-white rounded-lg  min-w-[200px] md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("Business");
              }}
            >
              {loading && voiceType === "Business" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "Business" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary in Bussiness Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-2 py-2.5 w-[100%] text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={(e) => {
                if (e.target.textContent === "Stop Summary") {
                  setVoiceType(null);
                  stopAudio();
                  return;
                }

                generateSummary("10th Grade");
              }}
            >
              {loading && voiceType === "10th Grade" ? (
                <p className="px-12">
                  <span class="w-3 h-3 border-2 border-t-transparent border-white rounded-full inline-block animate-spin mr-2"></span>
                  <span>Loading Voice.....</span>
                </p>
              ) : (
                <p>
                  {voiceType === "10th Grade" && audioPlaying
                    ? "Stop Summary"
                    : "Play Summary in 10th Grade Voice"}
                </p>
              )}
            </button>
            <button
              className="bg-[#393e46] px-5 py-2.5  w-[100%] text-white rounded-lg min-w-[200px]  md:w-2/3"
              onClick={() => setIsModalOpen(true)}
            >
              Check Violations
            </button>
          </div>
        </div>
        {enableChat && <Bot closeChat={closeChat} />}
        <button
          className=" md:w-16 md:h-16 bg-[#393e46] fixed bottom-[74px] right-4   py-2 px-2 sm:right-5 sm:py-2 sm-px-2 md:right-7 md:bottom-44 md:px-3 md:py-3 md:text-2xl  text-xl z-10 cursor-pointer hover:bg-[#00000098] bg-[#00000068] text-white rounded-full lg:bottom-16"
          onClick={openChat}
        >
          <svg
            class="w-5 h-5 md:w-9 md:h-9 lg:w-10 lg:h-10"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
            <path d="M8 12h.01"></path>
            <path d="M12 12h.01"></path>
            <path d="M16 12h.01"></path>
          </svg>
        </button>
      </div>
    </>
  );
}
