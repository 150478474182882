import { compliance } from "../../constants/constants";
import CommonMainSection from "../CommonMainSection";
import { Footer } from "../Footer";
import Hero from "../Hero";
import MainNavbar from "../MainNavbar";

export default function Compliance() {
  return (
    <>
      <MainNavbar />
      <Hero bgImage="" imageText={{ title: "Compliance", description:"Make Sure you know your laws" }} />
       <CommonMainSection content={compliance}/>
       <Footer/>
    </>
  );
}
