import { useCases } from "../../constants/constants";
import CommonMainSection from "../CommonMainSection";
import { Footer } from "../Footer";
import Hero from "../Hero";
import MainNavbar from "../MainNavbar";

export default  function UseCases(){
    return<>
    <MainNavbar/>
    <Hero bgImage="" imageText={{ title: "SMS Marketing Basics",description:"Glossary and Terms to Help you get started" }}/>
    <CommonMainSection content={useCases}/>
    <Footer/>

    </>
}