import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import WYSIWYGEditor from "./WYSIWYGEditor";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidenav from "./SIdeNav";

import BackToAdmin from "./BackToAdmin";
import Loader from "./Loader";
const EditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null);
  const [slug, setSlug] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [metadata, setMetadata] = useState({ description: "", keywords: "" });
  const [status, setStatus] = useState("draft");
  const [categories, setCategories] = useState("");
  const [isLoading, setIsLoading] = useState(true); 
   const [isSidenavOpen, setIsSidenavOpen] = useState(false); 
  const apiBaseUrl = process.env.REACT_APP_API_PATH;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFeaturedImage(file);
    }
  };

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/pages/getdetails/${id}`);
        setTitle(response.data.title);
        setContent(response.data.content);
        setSlug(response.data.slug);
        setFeaturedImage(response.data.featuredImages[0]);
        const metadata = response.data.metadata || {};
        setMetadata({
          description: metadata.description || "",
          keywords: metadata.keywords?.join(", ") || "",
        });
        setStatus(response.data.status);
        setCategories(response.data.categories);
        setIsLoading(false); // Stop loading once data is fetched
      } catch (err) {
        setError("Failed to load page data");
        setIsLoading(false); // Stop loading even on error
      }
    };
    fetchPage();
  }, [id, apiBaseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("content", content);
    if (featuredImage) {
      formData.append("featuredImage", featuredImage);
    }
    formData.append("status", status);
    formData.append("categories", categories);
    formData.append("metadata", JSON.stringify(metadata));

    try {
      await axios.put(`${apiBaseUrl}/admin/pages/edit/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      // navigate("/allpages", { state: { toastMessage: "The Page Has Been Updated!" } });
      toast.success("Page updated successfully");
      setTimeout(() => {
        navigate('/allpages')
      }, 1000);
    } catch (err) {
      toast.error("Failed to update page");
    }
  };

  return (
    
    <div className="flex flex-col lg:flex-row bg-white min-h-screen w-full flex-nowrap ">
      <div className="lg:hidden bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Edit Blog</h1>
        <button
          onClick={() => setIsSidenavOpen(!isSidenavOpen)}
          className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
        >
          
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <div
  className={`${
    isSidenavOpen
      ? "fixed inset-0 transform translate-x-0 bg-gray-800"
      : "fixed inset-0 transform -translate-x-full"
  } lg:relative lg:translate-x-0 lg:w-52   text-white p-4 transition-transform z-50 `}
>
  
  <Sidenav />
  <button
    onClick={() => setIsSidenavOpen(false)}
    className="lg:hidden absolute top-4 right-4 text-gray-300 hover:text-white"
  >
    Close
  </button>
</div>

    
    
    
    
  
      {/* Main Content */}
      <div className="flex-1 pl-2.5">
      <div className={`flex-1  items-center justify-center py-10 pt-16 pb-7  ${
          isSidenavOpen ? "opacity-50 pointer-events-none" : ""
        } transition-opacity duration-300`}
        
        >
        {isLoading ? (
         <Loader/>
        ) : (
          
            <form onSubmit={handleSubmit} className="bg-white shadow-lg  shadow-gray-700 pt-11 rounded-lg p-5 lg: space-y-6 w-[90%] mx-auto ">
            
         
            <div className=" hidden lg:flex justify-between items-center bg-gray-800 p-4 -mt-12 rounded-lg ">
            <h2 className=" text-white  font-semibold  ">Edit Blog</h2>
            <BackToAdmin/>
              </div>  
             
              {status ==="published" ?<small className="text-green-700 font-semibold">This page is already published</small>:<small className="text-yellow-500 font-semibold">This page is yet to be published</small>}
            <div>
                <input
                  type="text"
                  placeholder="Page Title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    const formedSlug = e.target.value
                      .trim()
                      .replace(/^\s+/, "")
                      .replace(/\s+/g, "-")
                      .split("-")
                      .slice(0, 4)
                      .join("-")
                      .toLowerCase();
                    setSlug(formedSlug.replace(/[^a-zA-Z0-9-]/g, ""));
                  }}
                  required
                  className="w-full px-4 py-3 mb-5 border border-gray-500 rounded-md focus:ring-2 focus:ring-gray-600 focus:outline-none focus-visible:outline-gray-500"
                />
                <WYSIWYGEditor value={content} onChange={setContent} className=" border border-gray-500 rounded-md py-5" />
                <div className="flex flex-col lg:flex-row items-center gap-4 mt-4">
                  <label
                    htmlFor="upload"
                    className="cursor-pointer bg-blue-500  hover:bg-blue-600 text-white text-sm font-medium px-4 py-2 rounded-md shadow-sm"
                  >
                    Update Image
                    <input
                      type="file"
                      id="upload"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                  {featuredImage ? (
                    <span className="text-sm text-gray-600">
                      {featuredImage.name || featuredImage}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-400">No file selected</span>
                  )}
                </div>
                {/* <textarea
                  placeholder="Meta Description"
                  value={metadata.description}
                  onChange={(e) =>
                    setMetadata((prev) => ({ ...prev, description: e.target.value }))
                  }
                  className="w-full px-4 py-3 mt-5 border border-gray-400 rounded-md focus:ring-2 focus:ring-gray-500 focus:outline-none focus-visible:outline-gray-500"
                /> */}
                {/* <input
                  type="text"
                  placeholder="Meta Keywords (comma-separated)"
                  value={metadata.keywords}
                  onChange={(e) =>
                    setMetadata((prev) => ({ ...prev, keywords: e.target.value }))
                  }
                  className="w-full px-4 py-3 mt-5 border border-gray-400 rounded-md focus:ring-2 focus:ring-gray-500 focus:outline-none focus-visible:outline-gray-500"
                /> */}
                <div className="flex gap-3 pt-2 mt-5">
                <button
                  type="submit"
                  onClick={() => setStatus("draft")}
                  className="w-full bg-gray-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-gray-600"
                >
                 {status ==="draft"? "Keep it Unpublished":"Unpublish"}
                </button>
                <button
                  type="submit"
                  onClick={() => setStatus("published")}
                  className="w-full bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-600"
                >
                  {status ==="published"?"Keep it Published":"Publish"}
                </button>
              </div>
              </div>
              
  
              
              
            </form>
          
          
        )}
      </div>

      </div>
      
    <ToastContainer />
    </div>
  );
  
};

export default EditPage;
