import logo from "../assets/images/logo4.png";
import React, { useContext, useState, useEffect, useRef } from "react";
import { PatentContext } from "../context/PatentContextProvider";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
export default function MainNavbar() {
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
 
  const {
    setAnimate,
    setPlayMusic,
    menuOpen,
    setMenuOpen,
    activeLink,
    setActiveLink,
    handleLinkClick,
  } = useContext(PatentContext);
  const [isAboutOpen, setIsAboutOpen] = useState(false); // Renamed state
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

//variables//
const isPartOfGettingStarted= "/working-model"||"/best-practices"||"/use-cases"||"/glossary"||"/compliance"
const isPartOfAboutPage="/about-us"||"/alert-system"||"/history"
  //functions//
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function navigateToHomePage() {
    setPlayMusic(false);
    setAnimate(false);
    navigate("/");
    window.scrollTo(0, 0);
  }

  //effects//
  useEffect(() => {
    // Check if the current route matches specific conditions
    const routesWithFalseState = ["/allpages", "/login", "/pages"];

    if (routesWithFalseState.includes(location.pathname)) {
      setActiveLink("/allpages");
    }
  }, [location.pathname]);
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 130);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full transition-transform duration-300 ease-in-out z-20 font-bold ${
          scroll ? "bg-[#393e46] z-20 fixed top-0 " : "bg-black fixed "
        } md:px-5 lg:px-20 flex justify-between items-center`}
      >
        <div
          className={`' ${
            scroll ? "h-20" : "h-32"
          } relative  flex justify-center items-center py-2'`}
          style={{ cursor: "pointer" }}
        >
          <img
            src={logo}
            alt="logo"
            className={` ${
              scroll ? "w-[4.3rem] sm:pl-0 pl-2" : "w-[7.3rem] pl-2"
            } `}
            onClick={navigateToHomePage}
          />
        </div>
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="text-3xl mr-2">
            {menuOpen ? (
              <AiOutlineClose className="text-white" />
            ) : (
              <AiOutlineMenu className="text-white" />
            )}
          </button>
        </div>
        {menuOpen && (
          <ul
            className={` absolute  right-0 bg-gray-200 w-full   py-36  md:py-[4.5rem] flex gap-8 flex-col items-center justify-center z-50 lg:text-[20px] md:text-[16px] ${"top-32"}`}
          >
            <li >
              <Link className="hover:bg-gray-600 hover:text-white p-2" to="/">Home</Link>
            </li>
            {/* <li>
              <Link
                to="/history"
                onClick={() => handleLinkClick("/history")}
                className={`block px-4 py-2 text-sm ${
                  activeLink == "/history" ? "text-[#00A4E5] " : "text-black"
                }`}
              >
                History
              </Link>
            </li> */}
             <li className="relative group">
              <Link
                // to="/working-model"
                // onClick={() => handleLinkClick("/working-model")}
                className={`block px-4 py-2 text-sm ${
                   activeLink === "/working-model"||activeLink==="/best-practices"||activeLink==="/use-cases"||activeLink==="/compliance"||activeLink==="/glossary"
                    ? "text-[#00A4E5] "
                    : "text-black"
                } hover:bg-gray-600 hover:text-white`}
              >
                Getting Started
                <span
      className={`ml-2 text-sm transform transition-transform duration-300 ease-in-out ${
        isPartOfAboutPage ? "rotate-180" : "rotate-0"
      }`}
    >
      ▼
    </span>
              </Link>

              <ul
    className="absolute left-0  hidden w-48 bg-white  group-hover:block  border border-gray-200 divide-y z-50 divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    <li>
      <Link
        to="/working-model"
        onClick={() => handleLinkClick("/working-model")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        How It Works
      </Link>
    </li>
    <li>
      <Link
        to="/best-practices"
        onClick={() => handleLinkClick("/best-practices")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Best Practices
      </Link>
    </li>
    
    <li>
      <Link
        to="/use-cases"
        onClick={() => handleLinkClick("/use-cases")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Use Cases
      </Link>
    </li>
    <li>
      <Link
        to="/compliance"
        onClick={() => handleLinkClick("/compliance")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Compliance
      </Link>
    </li>
    <li>
      <Link
        to="/glossary"
        onClick={() => handleLinkClick("/glossary")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Glossary
      </Link>
    </li>
  </ul>

        </li>
            <li className="relative group">
              <Link
                // to="/about-us"
                // onClick={() => handleLinkClick("/about-us")}
                className={`block px-4 py-2 text-sm ${
                  activeLink === "/about-us"||activeLink==="/history"||activeLink==="/alert-system" ? "text-[#00A4E5] " : "text-black"
                } hover:bg-gray-600 hover:text-white`}
              >
                About Us
                <span
      className={`ml-2 text-sm transform transition-transform duration-300 ease-in-out ${
        isPartOfAboutPage ? "rotate-180" : "rotate-0"
      }`}
    >
      ▼
    </span>
              </Link>
              <ul
    className="absolute left-0  hidden w-48 bg-white  group-hover:block  border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    <li>
      <Link
        to="/about-us"
        onClick={() => handleLinkClick("/about-us")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        About Us
      </Link>
    </li>
    <li>
      <Link
        to="/alert-system"
        onClick={() => handleLinkClick("/alert-system")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Alert System
      </Link>
    </li>
    <li>
      <Link
        to="/history"
        onClick={() => handleLinkClick("/history")}
        className="block px-4 py-2 text-[17px] text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        History
      </Link>
    </li>
  </ul>

            </li>

            {/* <li>
              <Link
                to="/alert-system"
                className={`block px-4 py-2 text-sm ${
                  activeLink === "/alert-system"
                    ? "text-[#00A4E5]"
                    : "text-black"
                }`}
              >
                Alert System
              </Link>
            </li> */}
            <li>
              <Link
                to="/contact-us"
                onClick={() => handleLinkClick("/contact-us")}
                className={`block px-4 py-2 text-sm ${
                  activeLink == "/contact-us" ? "text-[#00A4E5] " : "text-black"
                } hover:bg-gray-600 hover:text-white`}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/press-releases"
                onClick={() => handleLinkClick("/press-releases")}
                className={`block px-4 py-2 text-sm ${
                  activeLink == "/press-releases"
                    ? "text-[#00A4E5] "
                    : "text-black"
                } hover:bg-gray-600 hover:text-white`}
              >
                Press Releases
              </Link>
            </li>
            <li>
              <Link
                to="/our-blogs"
                onClick={() => handleLinkClick("/our-blogs")}
                className={`block px-4 py-2 text-xl ${
                  activeLink === "/our-blogs"
                    ? `${
                        scroll
                          ? "text-[#00A4E5]  border-b-4 border-[#00A4E5]"
                          : "text-white border-b-4 border-[#B0B0B0] py-2 px-4  transition delay-300 duration-300 ease-in-out"
                      }`
                    : `${scroll ? "text-white" : "text-white "}`
                }`}
              >
                Blogs
              </Link>
            </li>
          </ul>
        )}

        <ul className="hidden lg:flex text-white gap-1 text-2xl font-bold">
          <li>
            <Link
              to="/"
              onClick={() => handleLinkClick("/")}
              className={`block px-4 py-2 text-xl ${
                activeLink == "/" ? "border-b-4 border-[#B0B0B0]" : ""
              }`}
            >
              Home
            </Link>
          </li>

          <li className="relative group">
  <Link
     to={`${location.pathname}`}
    onClick={() => handleLinkClick(`${location.pathname}`)}
    className={`block px-4 py-2 text-xl ${
      activeLink === "/about-us"||activeLink==="/history"||activeLink==="/alert-system"
        ? `${
            scroll
              ? "text-[#00A4E5] border-b-4 border-[#00A4E5]"
              : "text-white border-b-4 border-[#B0B0B0] py-2 px-4 transition delay-300 duration-300 ease-in-out"
          }`
        : `${scroll ? "text-white" : "text-white "}`
    }`}
  >
    About 
    <span
      className={`ml-2 text-sm transform transition-transform duration-300 ease-in-out ${
        isPartOfAboutPage ? "rotate-180" : "rotate-0"
      }`}
    >
      ▼
    </span>
  </Link>
   

  {/* Dropdown Menu */}
  <ul
    className="absolute left-0  hidden w-48 bg-white  group-hover:block  border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    <li>
      <Link
        to="/about-us"
        onClick={() => handleLinkClick("/about-us")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        About Us
      </Link>
    </li>
    <li>
      <Link
        to="/alert-system"
        onClick={() => handleLinkClick("/alert-system")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Alert System
      </Link>
    </li>
    <li>
      <Link
        to="/history"
        onClick={() => handleLinkClick("/history")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        History
      </Link>
    </li>
  </ul>
</li>


          {/* <li>
            <Link
              to="/alert-system"
              onClick={() => handleLinkClick("/alert-system")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/alert-system"
                  ? `${
                      scroll
                        ? "text-[#00A4E5] border-b-4 border-[#00A4E5] "
                        : "  border-b-4 border-[#B0B0B0]   py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              Alert System
            </Link>
          </li> */}
          <li>
            <Link
              to="/contact-us"
              onClick={() => handleLinkClick("/contact-us")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/contact-us"
                  ? `${
                      scroll
                        ? "text-[#00A4E5]  border-b-4 border-[#00A4E5]"
                        : "text-white  border-b-4 border-[#B0B0B0]   py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              Contact Us
            </Link>
          </li>
          {/* <li>
            <Link
              to="/history"
              onClick={() => handleLinkClick("/history")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/history"
                  ? `${
                      scroll
                        ? "text-[#00A4E5] border-b-4 border-[#00A4E5]"
                        : "text-white border-b-4 border-[#B0B0B0] py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              History
            </Link>
          </li> */}
          <li>
            <Link
              to="/press-releases"
              onClick={() => handleLinkClick("/press-releases")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/press-releases"
                  ? `${
                      scroll
                        ? "text-[#00A4E5]  border-b-4 border-[#00A4E5]"
                        : "text-white border-b-4 border-[#B0B0B0] py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              Press Releases
            </Link>
          </li>
          <li className="relative group">
          <Link
     to={`${location.pathname}`}
    onClick={() => handleLinkClick(`${location.pathname}`)}
    className={`block px-4 py-2 text-xl ${
        activeLink === "/working-model"||activeLink==="/best-practices"||activeLink==="/use-cases"||activeLink==="/compliance"||activeLink==="/glossary"
        ? `${
            scroll
              ? "text-[#00A4E5] border-b-4 border-[#00A4E5]"
              : "text-white border-b-4 border-[#B0B0B0] py-2 px-4 transition delay-300 duration-300 ease-in-out"
          }`
        : `${scroll ? "text-white" : "text-white "}`
    }`}
  >Getting Started
  
  <span
      className={`ml-2 text-sm transform transition-transform duration-300 ease-in-out ${
        isPartOfAboutPage ? "rotate-180" : "rotate-0"
      }`}
    >
      ▼
    </span>
  </Link>
   <ul
    className="absolute left-0  hidden w-48 bg-white  group-hover:block  border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    <li>
      <Link
        to="/working-model"
        onClick={() => handleLinkClick("/working-model")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        How It Works
      </Link>
    </li>
    <li>
      <Link
        to="/best-practices"
        onClick={() => handleLinkClick("/best-practices")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Best Practices
      </Link>
    </li>
    
    <li>
      <Link
        to="/use-cases"
        onClick={() => handleLinkClick("/use-cases")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Use Cases
      </Link>
    </li>
    <li>
      <Link
        to="/compliance"
        onClick={() => handleLinkClick("/compliance")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Compliance
      </Link>
    </li>
    <li>
      <Link
        to="/glossary"
        onClick={() => handleLinkClick("/glossary")}
        className="block px-4 py-2 text-sm text-[#5c636e]  font-bold hover:bg-gray-600 hover:text-white"
      >
        Glossary
      </Link>
    </li>
  </ul>
  
  
  
  
  
  </li>
          <li>
            <Link
              to="/our-blogs"
              onClick={() => handleLinkClick("/our-blogs")}
              className={`block px-4 py-2 text-xl ${
                activeLink === "/our-blogs"
                  ? `${
                      scroll
                        ? "text-[#00A4E5]  border-b-4 border-[#00A4E5]"
                        : "text-white border-b-4 border-[#B0B0B0] py-2 px-4  transition delay-300 duration-300 ease-in-out"
                    }`
                  : `${scroll ? "text-white" : "text-white "}`
              }`}
            >
              Blogs
            </Link>
          </li>

        </ul>
      </div>
    </>
  );
}
