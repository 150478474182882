export default function QuestionsAnswers({ faqs }) {
 
  return (
    <>
      {faqs.map((faq) => (
        <div className="pb-8 px-4">
          <h3 className="text-[#212529] font-bold pb-2 text-2xl">
            {faq?.question}
          </h3>
          <p className="text-lg">{faq?.answer}</p>
        </div>
      ))}
    </>
  );
}
