import { useLocation } from "react-router-dom";
import TermDescription from "./Operations/TermDescription";
import { termsOfCompliance } from "../constants/constants";
export default function CommonMainSection({ content }) {
  const location = useLocation();
  const onCompliancePage = location.pathname === "/compliance";
  const onBestPracticePage=location.pathname === "/best-practices";
  return (
    <>
      <div className= "bg-white pt-10 pb-5 flex-grow ">
        <div className={location.pathname!=="/glossary"?"w-[85%] m-auto text-left":"w-full text-left"}>
          <h2 className="text-[#01a4e4] text-4xl  font-bold pb-4 text-left">
            {content.title}
          </h2>
          {content.description?.map((val) => (
            <p className="text-[#3b4455] text-xl pb-7">{val}</p>
          ))}
          {onCompliancePage && (
            <h2 className="text-[#01a4e4] text-4xl  font-bold pb-4 text-left ">
              SMS Compliance Explained
            </h2>
          )}
          {content.keyPoints?.map((item, index) => (
            <div>
              <h3 className="text-[#3B4455] text-3xl pb-5  ">{onBestPracticePage ?`${index +1}. ${item.feature}`:`${item.feature}`}</h3>

              {item?.featureInfo?.map((val) => {
                return (
                  <div>
                    <p className="text-[#3b4455] text-xl pb-5">{val}</p>
                  </div>
                );
              })}
              {onCompliancePage && index === 0 && (
                <ul className="text-lg pb-5 list-disc list-inside">
                  {content.listing?.map((listItem) => (
                    <li>{listItem}</li>
                  ))}
                </ul>
              )}
              {onCompliancePage && index === 2 && (
                <TermDescription bibliography={termsOfCompliance} />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
