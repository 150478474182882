import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RiAdminFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { MdAddAlert } from "react-icons/md";
import { TiContacts } from "react-icons/ti"
import { toast } from "react-toastify";
const Sidenav = () => {
  const navigate= useNavigate();
  function handleLogout() {
    localStorage.removeItem("token");
     navigate("/login", { state: { isLoggedOut: true } });
  }
  return (
    <div className="h-[96%]  w-52 bg-gray-800 pt-20 pb-4 text-white flex flex-col justify-between fixed rounded-lg">
      
      <div >
        
        <nav className="flex flex-col">
        
          <NavLink
            to="/allpages"
            className="px-4 py-2 hover:bg-gray-700 rounded  inline-flex gap-2  items-center z-50"
            
          >
            <RiAdminFill/>
            CMS
          </NavLink>
          {/* <NavLink
            to="/pages-create"
            className="px-4 py-2 hover:bg-gray-700 rounded  inline-flex gap-2  items-center z-50"
            
          >
            <IoMdAdd/>
            Create Blog
          </NavLink> */}
          <NavLink
            to="/alerts"
            className="px-4 py-2 hover:bg-gray-700 rounded inline-flex gap-2  items-center z-20"
            
          >
          <MdAddAlert />  
            Alert System
          </NavLink>
          <NavLink
            to="/contact-requests"
            className="px-4 py-2 hover:bg-gray-700 rounded inline-flex gap-2  items-center z-20"
            
          >
          <TiContacts />  
            Contact Requests
          </NavLink>
        </nav>
      </div>

      {/* Logout Button */}
      <div className="mb-4 px-4">
        <button
          className="w-full py-2 bg-gray-500 hover:bg-gray-600 rounded text-white font-bold"
          onClick={() => {
          
            handleLogout();
          
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidenav;
