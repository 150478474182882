import { Link } from "react-router-dom";
import React, { useContext, useState, useEffect, useRef } from "react";
import { PatentContext } from "../context/PatentContextProvider";
export default function ContactUs() {
  const { handleLinkClick } = useContext(PatentContext);
  return (
    <>
      <div className="bg-[#3b4455] py-11  ">
        <div className="w-11/12 flex justify-center items-center flex-col text-white">
          <h3 className="pb-3 text-4xl font-bold">Contact Us</h3>
          <p className="pb-6 text-2xl pl-10 lg:pl-0">
            We Generally Respond To emails and web submissions within 24 hours
          </p>
          <Link
            to={"/contact-us"}
            onClick={() => handleLinkClick("/contact-us")}
            className="bg-green-600 rounded-full text-white px-12 py-3 text-xl font-bold"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
}
