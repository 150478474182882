import { useLocation } from "react-router-dom";

export default function TermDescription({ bibliography }) {
  const location = useLocation();
  const onGlossaryPage = location.pathname === "/glossary";

  return (
    <div className="space-y-4"> {/* Add spacing between each term-description block */}
      {bibliography?.map((val, index) => (
        <div
          key={index}
          className={`flex ${onGlossaryPage ? "flex-row items-start gap-4" : "flex-col"} 
                      p-2`}
        >
          
          <h4
            className={`font-semibold text-xl ${
              onGlossaryPage ? "w-40 min-w-[160px] text-left pr-2" : "mb-2"
            } text-gray-800`}
          >
            {val.term}
            {onGlossaryPage && ":"}
          </h4>
          
          <p className="text-gray-600 text-lg flex-1">{val.description}</p>
        </div>
      ))}
    </div>
  );
}
