import React, { useContext, useState, useEffect, useRef } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { PatentContext } from "../context/PatentContextProvider";
import axios from "axios";
import Loader from "./ChatLoader";

const Bot = ({ closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const { selectedPatent } = useContext(PatentContext);
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef(null); // Create a ref to scroll to the latest message
  const textAreaRef = useRef(null);

  // Destructure patent details from selectedPatent
  const {
    abstract,
    claims,
    fieldOfInventor,
    fileDate,
    inventor,
    issued,
    patentId,
    title,
  } = selectedPatent;

  // Scroll to the latest message
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Format the AI response to remove unwanted symbols
  const formatResponse = (response) => {
    return response.replace(/[*_~`]/g, "").trim(); // Remove symbols like **, *, ~, etc.
  };

  const handleInputResize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set max height for textarea
    }
  };
  function handleEnterKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      resetHeight();
      sendMessage();
    }
  }
  function resetHeight() {
    return (textAreaRef.current.style.height = "auto");
  }
  const sendMessage = async () => {
    if (!input) return;

    const newMessages = [...messages, { text: input, sender: "user" }];
    setMessages(newMessages);
    setLoading(true);
    setInput("");

    const response = await getAIResponse(input);
    const formattedResponse = formatResponse(response); // Format the response//

    setMessages([...newMessages, { text: formattedResponse, sender: "ai" }]);
    resetHeight(); //reseting the height //
    setLoading(false);
    scrollToBottom();
  };

  const getAIResponse = async (userInput) => {
    try {
      const response = await fetchGeminiAI(userInput);
      return response;
    } catch (error) {
      return "Sorry, something went wrong.";
    }
  };

  const fetchGeminiAI = async (prompt) => {
    try {
      const getResponse = await axios.post(
        `${process.env.REACT_APP_API_PATH}/getContent`,
        {
          title: title,
          abstract: abstract,
          fieldOfInventor: fieldOfInventor,
          fileDate: fileDate,
          inventor: inventor,
          issued: issued,
          patentId: patentId,
          claims: claims,
          prompt: prompt,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const aiResponse =
        getResponse.data.response.candidates[0]?.content?.parts[0].text;
      return aiResponse;
    } catch (error) {
      console.error("Error:", error.message);
      return "Sorry, something went wrong.";
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="fixed z-50 bottom-0 right-0 md:right-5 md:bottom-5 w-full max-w-sm mx-auto bg-white rounded-lg shadow-md box-border">
      <div className="p-5 bg-blue-500 mb-3 rounded-lg flex gap-2 items-center w-full relative">
        <svg
          className="w-8 h-8 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <g data-name="message-circle">
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="16" cy="12" r="1"></circle>
            <circle cx="8" cy="12" r="1"></circle>
            <path d="M19.07 4.93a10 10 0 0 0-16.28 11 1.06 1.06 0 0 1 .09.64L2 20.8a1 1 0 0 0 .27.91A1 1 0 0 0 3 22h.2l4.28-.86a1.26 1.26 0 0 1 .64.09 10 10 0 0 0 11-16.28zm.83 8.36a8 8 0 0 1-11 6.08 3.26 3.26 0 0 0-1.25-.26 3.43 3.43 0 0 0-.56.05l-2.82.57.57-2.82a3.09 3.09 0 0 0-.21-1.81 8 8 0 0 1 6.08-11 8 8 0 0 1 9.19 9.19z"></path>
          </g>
        </svg>
        <h2 className="text-white text-xl pt-5">How May I Help You?</h2>
        <svg
          className="w-8 h-8 absolute top-[-7px] right-[-5px] text-[#e0e0e0] cursor-pointer"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          onClick={closeChat}
        >
          <path d="M10 1.6a8.4 8.4 0 1 0 0 16.8 8.4 8.4 0 0 0 0-16.8zm4.789 11.461L13.06 14.79 10 11.729l-3.061 3.06L5.21 13.06 8.272 10 5.211 6.939 6.94 5.211 10 8.271l3.061-3.061 1.729 1.729L11.728 10l3.061 3.061z"></path>
        </svg>
      </div>
      <div className="flex flex-col mb-4 h-52 box-border">
        <div className="overflow-y-auto overflow-x-hidden flex-1 flex flex-col px-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-3 rounded-lg  ${
                message.sender === "user"
                  ? "bg-[#B9D9EB] text-gray-900 self-end break-words p-3 rounded-lg my-3"
                  : "bg-gray-100 text-gray-800 self-start break-words"
              }`}
            >
              {message.text}
            </div>
          ))}
          {/* Display loader when AI is typing */}
          {loading && <Loader />}
          {/* ref element  */}
          <div ref={messageEndRef} />
        </div>
      </div>
      <div className="flex items-center gap-2 p-3">
        <textarea
          type="text"
          ref={textAreaRef}
          value={input}
          // onInput={handleInputResize}
          rows={1} // Start with one row
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          className="flex-grow p-3 border border-gray-400 rounded-lg focus:outline-none resize-none overflow-y-hidden"
          placeholder="Type your message..."
        />
        <button
          onClick={sendMessage}
          className="w-14 h-14 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m6.998 10.247l.435.76c.277.485.415.727.415.993s-.138.508-.415.992l-.435.761c-1.238 2.167-1.857 3.25-1.375 3.788c.483.537 1.627.037 3.913-.963l6.276-2.746c1.795-.785 2.693-1.178 2.693-1.832c0-.654-.898-1.047-2.693-1.832L9.536 7.422c-2.286-1-3.43-1.5-3.913-.963c-.482.537.137 1.62 1.375 3.788"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Bot;
