import {useContext } from "react";
import Hero from "../../../../components/Hero";
import { PatentContext } from "../../../../context/PatentContextProvider";
import useCMSApi from "../../hooks/useCMSApi";
import { Footer } from "../../../../components/Footer";
import { useNavigate } from "react-router-dom";
// import { PatentContext } from "../context/PatentContextProvider";

export default function Blogs(){
    const navigate=useNavigate();
    const { pages, loading, error } = useCMSApi();
  
  
  
    
    
  return <>
  <Hero bgImage="" imageText={{ title: "Our Blogs" }} />
  <div className=" bg-white m-auto py-5">
     <div className=" grid  gap-3 w-11/12 md:grid-cols-2 lg:grid-cols-3 grid-cols-1">
    {loading && 
      <div className="flex items-center justify-center h-screen w-screen bg-white">
            <div className="w-20 h-20 border-4 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
          </div>
     }
     {
       error && <div>Something went wrong......</div> 
     }
     {!loading &&!error  &&   pages?.map(page=> page.status==="published" && <div className="p-10 ">
       
        <div className="flex justify-center items-center">
        <img className="h-96 w-96 p-5" src={page.featuredImages[0] || ""} alt="image of a content"/>

        </div>
       {/* <p className="text-center font-bold py-2">{new Date(page.createdAt).toLocaleString()}</p> */}
        <p className="text-[#3b4455] text-xl text-center">{page?.metadata.description.slice(0, 122).slice(0, page.metadata.description.slice(0, 122).lastIndexOf(' ')) + "..."}
        </p>
        <div className="text-center">
        <button id="readMoreBtn" class="mt-4 px-4  underline  text-gray-400 font-medium text-lg rounded hover:text-gray-500 transition duration-200"
        
        onClick={()=>navigate(`/${page.slug}`)}
        >
      Read More
    </button>
      
          
        </div>
       
    

     </div>)}
    
     </div>



  </div>
  <Footer className="mt-7 fixed bottom-0"/>
  </>

























}