export default function PatentDescription({ patent }) {
  return (
    <>
      <div className="bg-black pt-36 pb-4">
        <div className="flex-col mb-10 px-14">
          <p
            className=" sm:text-3xl md:text-3xl lg:text-4xl font-bold text-center rounded-md text-white pb-3 font-roboto font-openfont"
            style={{ textShadow: "1px 0px 2px rgba(0,0,0,0.6)" }}
          >
            {patent?.title}
          </p>
          <p
            className="sm:text-lg md:text-xl  text-white text-center font-medium pb-8 font-openfont"
            style={{ textShadow: "1px 0px 2px rgba(0,0,0,0.6)" }}
          >
            {patent?.patentId}
          </p>
        </div>
      </div>
    </>
  );
}
