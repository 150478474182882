export default function BlogMainContent({content}){
  const styledContent = content?.replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
  ?.replace(/<h2>/g, '<h2 class="text-3xl font-semibold">')
  ?.replace(/<p>/g, '<p class="text-base leading-relaxed">')
  ?.replace(/<ul>/g, '<ul class="list-disc pl-5">')
  ?.replace(/<ol>/g, '<ol class="list-decimal pl-5">')
  ?.replace(/<li>/g, '<li class="mb-2">')
  ?.replace(/<a /g, '<a class="text-blue-500 underline" '); 


return<div className="bg-white  p-12 m-auto w-full h-full"



>
   
   <div className="prose"
            //   className="card-content"
              dangerouslySetInnerHTML={{ __html: styledContent }}
            />
    
    
    </div> 






}