import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
function ViolationModal({ claim, isModalOpen, setIsModalOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({mode:"onBlur"});
  const watchedData =watch();
  const onSubmit = (data) => {
    if (Object.keys(errors).length > 0) {
      return;
    }
    setCompetitorData(watchedData)
    checkViolations();
     reset();
    setBusinessSolution("");
  };

 //effect to remove scrolling//
 
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup when component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]);
  const [competitorData,setCompetitorData]=useState({})
  const [businessSolution, setBusinessSolution] = useState("");
  const [violationResult, setViolationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //functions //
  //function to check if a url is valid or not //
  const isValidUrl = (input) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // Protocol
      "((([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,})" + // Domain name
      "(\\:[0-9]{1,5})?" + // Optional port
        "(\\/.*)?$", // Optional path
      "i"
    );
    return urlPattern.test(input);
  };
 

  const checkViolations = async () => {
    if (!businessSolution?.trim() || !claim?.trim()) {
      setViolationResult("Please provide either bussiness solution or an url.");
      return;
    }
    setIsLoading(true);

    try {
      const isUrl = isValidUrl(businessSolution);
      const requestBody = isUrl
        ? { url: businessSolution, claim } // For URL scraping
        : { text: businessSolution, claim }; // For plain text analysis
      const endpoint = `${process.env.REACT_APP_API_PATH}/checkViolations`;
      const payload = requestBody;

      const result = await axios.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const analysisResult =
        result.data.response.candidates[0].content.parts[0].text;
      setViolationResult(analysisResult);
    } catch (error) {
      setViolationResult("An error occurred while checking violations.");
    } finally {
      setIsLoading(false);
      setBusinessSolution("");
    }
  };
   console.log(competitorData,"data saved")
  return (
    <>
      {isModalOpen && (
        <div className="bg-gray-100 flex items-center justify-center ">
          {/* Modal */}
          {isModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm flex items-start justify-center z-50 pt-20"
              onClick={(e) => {
                // Close modal if clicked outside the modal container
                if (e.target.id === "modalOverlay") setIsModalOpen(false);
                setViolationResult(null);
              }}
              id="modalOverlay"
            >
              {/* Modal Container */}
              <div className="bg-white rounded-lg shadow-lg w-1/3 p-6 relative  max-h-[90vh] overflow-y-auto">
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                    setViolationResult(null);
                  }}
                  className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  ✖
                </button>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <h2 className="text-xl font-semibold text-gray-800 overflow-auto">
                      Enter Details To Check Violations
                    </h2>
                  </div>
                  {/* Modal Body */}
                  <div class="mb-6">
                    <label
                      for="name"
                      class="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      {...register("name", {
                        required: "Company name is required",
                      })}
                      placeholder="Enter Company's Name"
                    />
                    {errors.name && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "12px",
                          display: "block",
                          paddingTop: "5px",
                        }}
                      >
                        {errors.name.message}
                      </small>
                    )}
                  </div>
                  <div class="mb-6">
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Email
                    </label>

                    <input
                      type="text"
                      name="email"
                      id="email"
                      class="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      {...register("email", {
                        required: "Email is required",
                        validate: {
                          maxLength: (v) =>
                            v.length <= 50 ||
                            "The email should have at most 50 characters",
                          matchPattern: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) || "email address must be a valid address",
                        },
                      })}
                      placeholder="Enter Email"
                    />

                    {errors.email && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "12px",
                          display: "block",
                        }}
                      >
                        {errors.email.message}
                      </small>
                    )}
                  </div>
                  <div class="mb-6">
                  <label
                      for="patent_number"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Contact number
                    </label>
                    <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    {...register("phone", {
                      required: "Phone number is required",
                      validate: {
                        isNumeric: (v) =>
                          /^\d{8}$/.test(v) ||
                          "Phone number must be exactly 8 digits",
                      },
                    })}
                    maxLength={8}
                    placeholder="Enter Phone Number"
                  />
                    {errors.phone && (
                      <small
                        style={{
                          color: "red",
                          fontSize: "12px",
                          display: "block",
                        }}
                      >
                        {errors.phone.message}
                      </small>
                    )}
                  </div>
                 

                  
                  <div className="mb-4">
                    <label
                      htmlFor="bussiness-solution"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Business solution or your company's Url
                    </label>
                    <textarea
                      type="text"
                      id="business-solution"
                      value={businessSolution}
                      onChange={(e) => setBusinessSolution(e.target.value)}
                      className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Enter Your Business Solution Here or a website url..."
                    />
                  </div>
                  {/* Modal Footer */}
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      // onClick={checkViolations}
                    >
                      Check Violations
                    </button>
                  </div>
                </form>
                {isLoading && (
                  <div className="flex justify-center items-center">
                    <span className="w-12 h-12 rounded-full animate-spin border-2 border-solid border-indigo-500 border-t-transparent"></span>
                    <span className="pl-5 text-2xl pt-2">
                      Checking for violations.....
                    </span>
                  </div>
                )}
                {!isLoading && violationResult && (
                  <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-lg">
                    {violationResult}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ViolationModal;
