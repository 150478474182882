import { Footer } from "../Footer";
import Hero from "../Hero";
import MainNavbar from "../MainNavbar";
import { useLocation } from "react-router-dom";
import TermDescription from "./TermDescription";
import { glossaryContent, termsOfGlossary } from "../../constants/constants";
import CommonMainSection from "../CommonMainSection";

export default function Glossary() {
  return (
    <div>
      <MainNavbar />
      <Hero bgImage="" imageText={{ title: "SMS Marketing Basics",description:"Glossary and Terms to Help you get started" }} />
      <div className=" bg-white pt-10 pb-5 flex-grow ">
        <div className="w-[85%] m-auto text-left">
          <p className="text-[#3b4455] text-xl pb-7">
            In any field, a basic understanding of the language and terms
            involved are necessary. We define some of the terms here .
          </p>
          <h2 className="text-[#01a4e4] text-4xl  font-bold pb-4 text-left">
            Mobile Marketing Glossary
          </h2>
          <TermDescription bibliography={termsOfGlossary} />
          <h2 className="text-[#01a4e4] text-4xl  font-bold pb-5 text-left pt-12">
            Some Common Use Cases for Mobile Marketing
          </h2>
          <p>
            Businesses generally utilize Mobile Marketing to drive traffic and
            increase conversions in their campaings. That is the primary reason
            why Marketers use SMS Marketing however campaigns can serve other
            purposes such as increasing mindshare in the customer, customer
            retention, dissemination, education, follow-up, two way
            communication, lead generation and reminders to highlight a few.
          </p>
          <CommonMainSection content={glossaryContent} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
